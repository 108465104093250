@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

html,
body {
  height: 100%;
  margin: 0;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: none; /* Ensure no background is set */
  overflow: hidden; /* Prevent overflow from crop selection */
}

.form-group input[type="date"] {
  width: 100%;
  padding: 0.7em;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  background: #fff;
  font-size: 14px;
  -webkit-appearance: none; /* For Chrome and Safari */
  -moz-appearance: none; /* For Firefox */
}

.tab-container {
  position: absolute; /* Position the entire container above the map */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10; /* Appear above the map */
}

.mapboxgl-popup-content {
  width: fit-content;
  border-radius: 8px;
}

.custom-marker {
  width: auto; /* Make the width adjust based on content */
  min-width: 50px; /* Minimum width should still be 50px */
  height: 30px;
  padding: 8px 10px;
  background: linear-gradient(135deg, #6db33f, #4c8c4a);
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  text-align: center;
}

.marker-number {
  margin-right: 4px; /* Gives a little space between number and unit */
}

.marker-unit {
  font-weight: normal; /* Optional: if you want the unit to be less emphasized than the number */
}

.view-switcher {
  position: absolute; /* Position view-switcher absolutely */
  top: 0; /* Set to the top */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.7em;
  z-index: 10;
}

.view-switcher button {
  cursor: pointer;
  border: none;
  padding: 0.7em 1.2em;
  font-size: 1rem;
  margin-right: 0.3em;
  background: #f0f0f0;
  outline: none;
  transition: background 0.3s ease;
}

.view-switcher button.active {
  background: #4caf50;
  color: #ffffff;
}

.crop-selection {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.7em;
  max-width: 16em;
  margin: 0 auto;
  margin-top: 0.7em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1em;
}

.crop-selection label {
  display: block;
  font-weight: 600;
  color: #333;
}

.crop-selection select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  background: #fff;
  font-size: 14px;
}

.crop-selection label,
.crop-selection select {
  margin-right: 0.7em;
}

.map-container {
  position: relative;
  width: 100%;
  max-width: 100vw; /* Ensure the container doesn't exceed the viewport width */
  height: 100vh;
  overflow: hidden; /* Hide any overflowing content */
}

@media screen and (max-width: 768px) {
  .crop-selection {
    flex-direction: column;
    align-items: flex-start;
  }
  .crop-selection label,
  .crop-selection select {
    margin-top: 0.7em;
  }
}

/*Tab Switch*/

/* tab-switch styles */
.tab-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  border-radius: 27.5px;
  width: 350px;
}

.tab-switch:after {
  content: "";
  position: absolute;
  width: 33.33%;
  top: 0;
  transition: left cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.4s;
  border-radius: 27.5px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(135deg, #6db33f, #4c8c4a);
  height: 100%;
  z-index: 0;
}

.tab-switch.left:after {
  left: 0;
}
.tab-switch.center:after {
  left: 33.33%; /* Position at the middle for the second tab */
}

.tab-switch.right:after {
  left: 66.66%; /* Position at two-thirds for the third tab */
}

.tab-switch .tab {
  display: inline-block;
  width: 33.33%;
  padding: 12px 12px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 200ms;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  user-select: none;
  color: #6db33f;
  text-align: center;
  background: transparent;
}

.tab-switch .tab.active {
  color: #ffffff;
}

.wrapper {
  border-radius: 2em;
  top: 0.7em;
  max-width: 20em;
  position: absolute;
  background-color: transparent;
  padding: 8px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  margin: 0 auto;
}

/*Hamburger*/

.crop-selection-container {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1000;
  padding-top: 0.5em;
}

.crop-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  display: none; /* Initially hidden */
}

.crop-selection {
  display: none;
}
@media screen and (min-width: 768px) {
  .crop-selection {
    display: block;
  }
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  display: none; /* Initially hidden */
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Mobile view */
@media screen and (max-width: 768px) {
  .crop-toggle-btn {
    display: block; /* Show the toggle button */
  }

  .crop-selection.open {
    animation: slideInFromRight 0.4s forwards;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: 16em;
    overflow-y: scroll;
    background: rgba(255, 255, 255); /* Same background color as your form */
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
  }
  .close-btn {
    display: block; /* Show the close button on mobile */
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    position: absolute; /* Absolute positioning */
    right: 1.4em; /* Distance from the right edge of the container */
  }

  .crop-selection.open .close-btn {
    display: block; /* Show the close button when the menu is open */
  }
  .tab-switch {
    width: 300px; /* Adjust this value to your desired width */
  }

  .tab-switch .tab {
    font-size: 12px; /* Adjust this value to your desired font size */
  }
}

@media screen and (max-width: 767px) {
  .crop-toggle-btn {
    display: block; /* Show the toggle button for < 768px */
  }
  .crop-selection {
    display: none; /* Hide crop selection for < 768px */
  }
}
@media screen and (min-width: 768px) {
  .crop-toggle-btn {
    display: none; /* Hide the toggle button for >= 768px */
  }
  .crop-selection {
    display: block; /* Show crop selection for >= 768px */
  }
}

.info-box-hidden {
  display: none;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.info-box-displayed {
  display: block;
  /* The rest of the styles are the same as .info-box-hidden */
}

.attribution-button {
  position: absolute;
  right: 0;
  bottom: 50px;
  background-color: white;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 29px;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 29px;
  border-radius: 4px;
  margin-right: 0.75em;

  /* ... your other styles ... */
}

@media (max-width: 768px) {
  .mapboxgl-ctrl-bottom-right {
    transform: translateY(
      -16vh
    ); /* This pushes the component up by 30px on mobile devices */
  }

  .attribution-button {
    transform: translateY(-16vh);
  }
}

.legend-container {
  position: absolute;
  bottom: 2vh;
  right: 1vw;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

/* Adjust positioning for smaller viewport heights (e.g., mobile in portrait) */
@media screen and (max-height: 500px) {
  .legend-container {
    bottom: 16vh;
  }
}

/* Adjust for smaller viewport widths (e.g., mobile) */
@media screen and (max-width: 768px) {
  .legend-container {
    bottom: 16vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .legend-container {
    bottom: 10vh;
  }
}

.legend-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.legend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;
}

.legend-color {
  width: 25px;
  height: 10px;
  margin-right: 5px;
}

.legend-value {
  font-size: 12px;
  color: black;
}

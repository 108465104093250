.attribution-modal {
  background-color: #1e1e1e; /* Dark background for a futuristic feel */
  border-radius: 8px;
  width: 70vw;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px #4caf50; /* Green-tinted shadow */
  overflow: auto;
  color: #fff; /* White text for better contrast */
  max-width: 400px;
}

.close-btn2 {
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  transition: color 0.2s;
  z-index: 1000;

  &:hover {
    color: #4caf50;
  }
}

.attribution-modal h2,
.attribution-modal h3 {
  border-bottom: 2px solid #4caf50;
  padding-bottom: 5px;
}

.attribution-modal h2 {
  margin-top: 0;
}

.attribution-modal .section-title {
  font-weight: bold;
  margin-top: 20px;
}

.attribution-modal ul {
  list-style-type: disc;
  margin-left: 20px;
}

.attribution-modal hr {
  border: none;
  border-top: 1px dashed #4caf50; /* Dashed line for a futuristic touch */
  margin: 20px 0;
}

.attribution-modal a {
  color: #4caf50; /* Email link with the theme color */
  transition: color 0.2s;
}

.attribution-modal a:hover {
  color: #36a473;
}

@media (max-width: 768px) {
  /* Adjust the max-width as per your mobile breakpoint requirement */
  .attribution-modal {
    transform: translate(-50%, -45%);
  }
}

.mapboxgl-popup-close-button {
  top: 0.5em; /* Adjust accordingly */
  right: 0.5em;
  font-size: 1.5em; /* Adjust accordingly */
  pointer-events: auto;
  z-index: 10000;
}

.popup-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background: transparent;
  z-index: 1000;
  width:650px;
  max-width: 500px;
}

.requirement-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.details-section {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 5px auto 0;
}

.details-section p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.details-section strong {
  font-weight: bold;
  flex-basis: 50%; /* occupies half the space and aligns to the left */
  text-align: left;
}

.details-section span {
  flex-basis: 50%; /* occupies the other half */
  text-align: center; /* centering the content within its half */
}

.popup-container h2,
#crop-water-req {
  margin-right: 10px;
  display: block; /* Changed to block */
  text-align: center;
}

.popup-container h2 {
  font-size: 20px;
  color: #4c8c4a;
  margin-bottom: 10px;
}

.popup-container p {
  font-size: 14px;
  color: #333;
}

#crop-water-req {
  font-weight: bold;
}

.requirement-section #crop-water-req {
  font-size: 16px; /* Adjust to your preferred font size */
}

.loading-spinner {
  border: 5px solid rgba(0, 0, 0, 0.1); /* Light gray border */
  border-radius: 50%; /* Circle shape */
  border-top: 5px solid #6db33f; /* Green top border to match the agriculture theme */
  width: 40px; /* Width of the spinner */
  height: 40px; /* Height of the spinner */
  animation: spin 1s linear infinite; /* Rotation animation */
  margin: auto; /* Center the spinner if inside a flex container or fixed-size parent */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-form {
  padding: 6px;
  background: transparent; /* Same background as the crop selection */
  border: transparent;
  border-radius: 8px; /* Matching the border-radius with crop selection */
  font-family: Arial, sans-serif;
  min-width: 250px;
  /* Centering similar to the crop selection */
}

.popup-form label {
  display: block;
  font-weight: 600;
  margin: 5px 0 5px;
  color: #333;
}

.popup-form select,
.popup-form input {
  width: 100%;
  padding: 10px; /* Padding consistent with other form elements */
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none;
  background: #fff;
  font-size: 14px; /* Matching font size with other form elements */
}

.popup-form button {
  cursor: pointer;
  padding: 12px;
  background: linear-gradient(135deg, #6db33f, #4c8c4a);
  border: none;
  color: #ffffff;
  width: 100%;
  font-size: 16px;
  transition: background 0.3s ease;
  border-radius: 5px;
}

.popup-form button:hover {
  background: #45a049;
}

.error-message {
  color: #d32f2f;
  font-size: 12px;
  margin-bottom: 10px;
}

.water-volume-label,
.water-volume-value {
  display: inline; /* to make them display on the same line */
  font-size: 1em; /* making it slightly smaller than regular text */
  color: #555; /* a muted color for distinction */
  margin-right: 0.3em; /* some spacing between label and value */ /* prevent them from wrapping to the next line */
}

.water-volume-value {
  font-weight: bold; /* to emphasize the value */
  color: #333; /* make the value a bit darker than the label */
}
